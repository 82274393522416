.nav-bar{
    background-color: #96a36ca1;
    width: 100%;
    height: 90px;
    position: absolute;
    z-index: 999;
}
.header-img{
    width: 95px;
    height: 80px;
    margin-left: 70px;
}
.nav-ul{
    float: right;
    margin-right: 20px;
}
.nav-item{
    display: inline-block;
    line-height: 100px;
    margin: 0 5px;
}
.nav-link{
    font-size: 22px;
    font-family: 'Amiri', serif;
    padding: 10px 20px;
    color: #ffffff;
    text-decoration: none;
}
.nav-link:visited{
    color: #ffffff;
    text-decoration: none;
}
.nav-link:hover{
    background: #FEFAE0;
    border-radius: 5px;
    transition: .5s;
    color: #BC6C25;
    text-decoration: none;
}
.checkbtn{
    font-size: 40px;
    color: #ffffff;
    float: right;
    line-height: 110px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}

                /* Tablet */ 
@media screen and (max-width: 860px) {
    .nav-bar{
        background: #96a36c;
        position: fixed;
        z-index: 999;

    }
    .checkbtn{
       display: block;
    }
    .header-img{
        margin-left: 25px;
    }
    .nav-ul {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100vh;
        background: #96a36c;
        top: 85px;
        right: -110%;
        text-align: center;
        transition: all .5s;
    }
    .nav-item{
        display: block;
        margin: 10px 0;
        line-height: 60px;
    }
    .nav-link{
        font-size: 20px;
    }
    .nav-link:hover {
        background: #FEFAE0;
        color: #BC6C25;
    }
    #check:checked ~ ul{
        right: -20px;
    }
    
}
