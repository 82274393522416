.AboutUs{
    text-align: center;
    color: black;
    padding: 10px;
    line-height: 3rem;
}
.AboutUs-title{
    margin-top: 135px;
    font-size: 35px;
    font-family: 'Amiri', serif;
}
.AboutUs-section1{
    font-size: 18px;
    padding: 20px 0;
    font-family: 'Tajawal', sans-serif;
}
.AboutUs-reserve{
    display: flex;
    justify-content: center;
}
.AboutUs-reservebtn{
    background: linear-gradient(to right, #BC6C25, #663300);
    padding: 10px 20px;
    border-radius: 15px;
    color: #ffffff;
    font-size: 15px;
}
.AboutUs-reservebtn:hover{
    background: linear-gradient(to right, #c98951, #BC6C25);
}
.AboutUs-section{
    padding: 20px 0;
    background: #FFFDF3;
    border-radius: 5px;
    margin: 0 10%;
}
.AboutUs-section2{
    font-size: 18px;
}
.location{
    padding: 20px 10px;
    text-align: center;
}

.location-title{
    font-size: 20px;
    font-family: 'Tajawal', sans-serif;
}
.city-location{
    text-align: center;
    margin: 0 10%;
}
.city-location-title{
    font-size: 25px;
    font-weight: bold;
    font-family: 'Prosto One', cursive;
    color: #1C1C1C;
}
.city-location-p{
    font-size: 16px;
    font-family: 'Tajawal', sans-serif;
}

                /* Tablet */ 
@media screen and (max-width: 860px) {
    .AboutUs-section1{
        margin: 0 10%;
    }
    .AboutUs-title{
        padding-top: 120px;
        margin-top: 0;
        font-size: 35px;
        font-family: 'Amiri', serif;
    }

}

                /* Mobile */
@media screen and (max-width: 600px) {
    .AboutUs-section1{
        margin: 0 5%;
    }
    .AboutUs-section{
        margin: 0 5%;
    }
    .AboutUs-title{
        padding-top: 120px;
        font-size: 35px;
        font-family: 'Amiri', serif;
    }
    .city-location{
        margin: 0 5%;
    }
} 
