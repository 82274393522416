.popup{
    position: fixed;
    top :0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border-radius: 10px;
}

.close-btn{
    font-size: 25px;
    position: absolute;
    top: 7px;
    right: 9px;
}