@import "~react-image-gallery/styles/css/image-gallery.css";

.Activities-box{
    display: flex;
    justify-content: space-around;
}
.Activites-title{
    padding-top: 140px;
    text-align: center;
    color: #1C1C1C;
    font-size: 35px;
    font-family: 'Amiri', serif;
}
.activity-card{
    text-align: end;
    width: 50%;
    padding-right: 5%;
}
.Activites-h2{
    text-align: center;
    font-size: 23px;
    font-family: 'Tajawal', sans-serif;
}
.Activites-h3{
    text-align: end;
    font-size: 27px;
    font-family: 'Amiri', serif;
}
.Activites-description{
    text-align: end;
    padding: 0px 20px 15px 20px;
    color: black;
    font-size: 20px;
    line-height: 3rem;
    font-family: 'Tajawal', sans-serif;
}
.Activites-gallery{
    padding: 30px 20px 0 20px;
}
.Activites-img{
    display: flex;
    justify-content: center;
}

/* Tablet */

@media only screen and (max-width: 860px) {
    .Activites-title{
        padding-top: 140px;
    }
    .Activites-h2{
        padding: 0 10%;
    }
    .Activities-box{
        display: grid;
        padding-top: 5%;
    }
    .activity-card{
        text-align: center;
        width: 100%;
    }
    .Activites-h3{
        text-align: center;
    }
}

/* Mobile */

@media only screen and (max-width: 600px) {
    
}