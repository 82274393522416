*::before,
*::after {
  box-sizing: border-box;
} 
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Amiri', serif;
}
h1{
  margin: 0;
}
main {
  min-height: calc(100vh - 220px);
}

