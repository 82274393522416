.Home{
    height: auto;
}
.Sliderimg{
    height: 300px;
    margin-bottom: 50px;
}
.Home-titles{
    font-family: 'Amiri', serif;
    font-weight: bold;
    font-size: 30px;
    color: #1C1C1C;
}
.Service-container{
    background-color: #ffffff;
    margin-top: 250px;
    padding: 75px 0 0 0 ;
    text-align: center;
    display: flex;  
}
.Service-text{
    width: 50%;
    padding: 100px 5% 0 5%;
}
.service-titles{
    font-size: 33px;
    font-weight: bold;
}
.service-p{
    padding-top: 30px;
    font-size: 18px;
}
.Service-categories{
    width: 50%;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
}
.category{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*  BestSeller */


.BestSeller-container{
    background-color: #EDF1D6;
    padding: 70px 0 100px 0 ;
    text-align: center;
    margin-top: 20px;
    display: flex;
}
.best-text{
    width: 50%;
    padding: 100px 5% 0 5%;
}
.best-title{
    font-size: 28px;
    font-weight: bold;
}
.best-p{
    font-size: 18px;
    line-height: 1.7;
    padding: 0 20px;
}
.BestSeller-logement{
    width: 50%;

}
.BestSeller-img{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.bestimg{
    width: 100%;
    height: auto;
    border-radius: 10px;
}
.best-link{
    text-decoration: none;
    color: #1C1C1C;
}
.best-link:hover{
    text-decoration: none;
    color: #4d4618;
}


            /* Tablet */

@media only screen and (max-width: 950px) {
    .Service-container {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 140px;
        padding: 55px 0 0 0 ;
       
    }
    .Service-text{
        width: 100%;
        padding: 130px 5% 0 5%;
    }
    .Service-categories{
        width: 100%;
    }
    .service-p{
        padding: 20px 30px 0 30px;
    }
    .BestSeller-container{
        display: flex;
        flex-direction: column;
        padding: 0px 0 100px 0 ;
    }
    .best-text{
        width: 100%;
        padding: 50px 5% 0 5%;
    }
    .best-logment-title{
        padding-top: 15px ;
    }
    .best-p{
        padding: 0px 30px 20px 30px;
    }
    .BestSeller-logement{
        width: 100%;
    }
}

            /* Mobile */

@media only screen and (max-width: 600px) {
    .Services {
        display: grid;
        grid-template-columns: auto ;
        gap: 100px;
        padding: 20px 20px 40px 20px;
        margin: 20px;
    }
    .category{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .BestSeller{
        display: grid;
        grid-template-columns: auto ;
        gap: 100px;
        padding: 20px 5px 40px 5px;
        margin: 20px;
    }
}