@import "~react-image-gallery/styles/css/image-gallery.css";

.Cars-title{
    padding-top: 140px;
    text-align: center;
    color: #1C1C1C;
    font-family: 'Amiri', serif;
    font-size: 35px;
}
.Cars-description{
    text-align: center;
    padding: 20px 20px 40px 20px;
    color: black;
    font-size: 20px;
    line-height: 3rem;
    font-family: 'Tajawal', sans-serif;
}
.Cars-gallery{
  padding: 0 20px;
}
.Cars-img{
    display: flex;
    justify-content: center;
}

/* Tablet */

@media only screen and (max-width: 768px) {
    .Cars-title{
        padding-top: 140px;
    } 
}

/* Mobile */

@media only screen and (max-width: 600px) {
    
}