.gallery {
    position: relative;
    margin-bottom: 39px;
    padding-top: 100px;
}

.gallery img {
    object-fit: cover;
}
.filtreGallery {
    position: absolute;
    margin-top: -100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.gallery,.gallery img,.filtreGallery{
    width: 340px;
    height: 340px;
} 
.gallery img,.filtreGallery {
    border-radius: 10px;
}
.filtreGallery:hover {
    border: 2px solid #e0d9d9;
    box-shadow: 3px #cfcecea2;
}
.gallery-price {
    position: absolute;
    margin-left: 225px;
    margin-top: -11%;
    font-size: 19px;
    line-height: 20px;
    color: #ffffff;
}
.gallery-title{
    position: absolute;
    margin-left: 13px;
    margin-right: 20px;
    margin-top: -11%;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
}

@media only screen and (max-width: 860px) {
    .gallery {
        padding-top: 0px;
    }
}