.LogementContainer{
    display: flex;
    justify-content: center;
    margin-top: 0px;
    padding-top: 130px;
}
.LogementWrapper{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}
                /* Section 1 */

.Logement-Banner{
    position: relative;
}
.Imagecover{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.Imgcover{
    width: 90%;
    height: 400px;
    object-fit: cover;
    border-radius: 15px;
    
}
.AffichePlus{
    display: flex;
    justify-content: flex-end;
    margin-right: 60px;
    margin-top: -60px;
    cursor: pointer;
    position: absolute;
    bottom: 8%;
    right: 0;   
}
.AffichePlusBtn{
    background: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 12px;
    font-family: 'Amiri', serif;
}
.plus-icon{
    align-items: center;
    padding: 0 3px;

}
.AffichePlusBtn:hover {
    background: linear-gradient(to right, #BC6C25, #663300);
    color: #ffffff;
}

.AffichePlusBtn:active {
  background-color: #c98951;
  box-shadow: 0 1px #BC6C25;
  transform: translateY(2px);
}

                /* Section 2 */

.titre{
    width: 90%;
    text-align:end;

}
.titre h2{
    color: #283618;
    font-size: 36px;
    font-weight: bold;
    font-family: 'Prosto One', cursive;
}
                /* Section 3 */
.price-location{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 94%;
    padding-left: 60px;
}
.Details{
    color: #1C1C1C;
    font-size: 15px;
    background-color: #EDF1D6;
    padding: 10px 40px;
    border-radius: 15px;
    font-family: 'Tajawal', sans-serif; 
}
.Price{
    font-size: 20px;
    font-weight: bold;
    font-family: 'Tajawal', sans-serif;
}

                /* Section 4 */

.reserve{
    display: flex;
    justify-content: flex-end;
    width: 94%;
}
.reservebtn{
    background: linear-gradient(to right, #BC6C25, #663300);
    padding: 10px 20px;
    border-radius: 15px;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Prosto One', cursive;
    font-weight: bold;
}
.reservebtn:hover{
    background: linear-gradient(to right, #c98951, #BC6C25);
}

                /* Section 5 */
.descript {
    margin: 15px 60px 50px 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #EDF1D6;
    border-radius: 10px;
    padding: 30px 20px;
}
.descript-title {
    padding: 10px 20px;
    font-family: 'Prosto One', cursive;
    color: #1C1C1C;
}
.descript-section{
    text-align: center;
    width: 50%;
    font-size: 18px;
    font-family: 'Tajawal', sans-serif;
    color: black;
}
.Logement-Description{
    line-height: 1.6;
    padding: 20px 20px 0 20px;
    text-align: end;
}
.Logement-Equipment {
    padding: 20px 100px 0 0;
    list-style-type: none;
    line-height: 1.6;
    text-align: end;
}
                /* Responsive Tablette */

 @media (max-width: 991px){
    .price-location{
        padding-left: 40px;
    }
    .descript {
        margin: 15px 20px 50px 20px;
    }
    .Logement-Equipment {
        padding: 20px 40px 0 0;
    }
 }
                 /* Responsive Mobile */

@media (max-width: 860px){
    .LogementContainer{
        display: flex;
        justify-content: center;
        margin-top: 0px;
        padding-top: 140px;
    }

    .LogementWrapper{
        width: 100%;
    }
    .AffichePlus{
        bottom: 8%;
        right: -20px;   
    }
    .price-location{
        display: flex;
        flex-direction: column;
    }
    .Details{
        color: #1C1C1C;
        font-size: 14px;
        padding: 10px 30px;
    }
    h3{
        margin-top: 0;
    }
    .reserve{
        display: flex;
        justify-content: flex-start;
        margin-left: 22px;
    }
    .descript {
        display: flex;
        flex-direction: column;
    }
    .descript-section{
        width: 100%;
    }
    .Logement-Equipment {
        padding: 20px 20px 0 0;
    }
    
}