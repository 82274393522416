.photo  {
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    height: 450px;
}

.precedent, .suivant {
    margin-top: 35%;    
    position: absolute;
    font-size: 30px;
    cursor: pointer;
    
}
.precedent {
    margin-left: -4.7%;
}
.navigation .suivant {
    margin-left: 90%;
}
.navigation p {
    font-size: 16px;
    font-weight: bold;
    color: #1C1C1C;
    text-align: center;
    padding-top: 79.5%;
}

            /* Responsive Tablet*/

@media screen and (max-width: 635px){
    .precedent, .suivant {
        margin-top: 35%;     
    }
    .precedent {
        margin-left: -5%;
    }
    .navigation .suivant {
        margin-left: 90%;
    }
    .navigation p {
        padding-top: 80%;
    }          
}
@media screen and (max-width: 560px){
    .precedent, .suivant {
        margin-top: 40%;     
    }
    .precedent {
        margin-left: -5.5%;
    }
    .navigation .suivant {
        margin-left: 87%;
    }
    .navigation p {
        padding-top: 92%;
    }          
}
            /* Responsive Mobile */
@media screen and (max-width: 460px){
    .precedent, .suivant {
        margin-top: 45%;     
    }
    .precedent {
        margin-left: -5.5%;
    }
    .navigation .suivant {
        margin-left: 85%;
    }
    .navigation p {
        padding-top: 115%;
    }          
}
@media screen and (max-width: 400px){
    .precedent, .suivant {
        margin-top: 55%;     
    }
    .precedent {
        margin-left: -7.5%;
    }
    .navigation .suivant {
        margin-left: 83%;
    }
    .navigation p {
        padding-top: 137%;
    }          
}
@media screen and (max-width: 350px){
    .precedent, .suivant {
        margin-top: 70%;     
    }
    .precedent {
        margin-left: -8%;
    }
    .navigation .suivant {
        margin-left: 80%;
    }
    .navigation p {
        padding-top: 160%;
    }          
}