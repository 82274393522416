.Footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #96a36c;
    width: 100%;
    height: 110px;
}
footer img {
    width: 100px;
    height: 85px;
}

.socila-media{
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Tajawal', sans-serif;
    margin: 0 7px;
}
.socila-media:visited{
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Tajawal', sans-serif;
    margin: 0 7px;
}
.socila-media:hover{
    color: #f8f0ba;
    text-decoration: none;
}
.IF-social{
    display: flex;
}
.design{
    padding-top: 8px;
    color: #fff;
    text-decoration: none;
    text-align: end;
    font-size: 12px;
}
.design-a{
    color: #e2d47f;
    text-decoration: none;
}
.design-a:visited{
    color: #e2d47f;
    text-decoration: none;
}
@media only screen and (max-width: 768px) {

}


/* Mobile */

@media only screen and (max-width: 600px) {
    .Footer{
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 20px 0;
    }
    footer img {
        width: 100px;
        height: 85px;
        margin-bottom: 10px;
    }
    .IF-social{
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }
   
}