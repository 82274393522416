.accordion-simple > .active{
    display: block;
}
.accordion__faq .inactive{
    display: none;
}
.accordion__faq > div{
    background-color: #FFF;
    border-style: groove;
    margin-bottom: 20px;
    padding: 0 15px;
    border-radius: 22px;
}
.accordion__title{
    color: #000;
}
.accordion__faq-heading{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}
.accordion__faq-heading h3{
    font-weight: bold;
    font-size: 22px;
    color: #000;
}

.Accordion-text{
    text-align: end;
    padding: 10px 0;
    color: #000;
    font-size: 17px;
    line-height: 1.4;
}
.verticle{
    font-size: 25px;
    color: #000;
    margin-top: 70px;
    cursor: pointer;
}
            /* Mobile */

@media only screen and (max-width: 620px) {
    .accordion__faq-heading h3{
        font-size: 18px;
    }
}