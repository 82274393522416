.Slider{
    height: 190%;
    position: relative;
    text-align: center;
}
.description{ 
    position: absolute;
    color: black;
    display: flex;
    justify-content: center;
    top: 45%;
    width: 100%;
}
.SliderText{
    color: #ffffff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.description h1{
    font-size: 48px;
    font-family: 'Prosto One', cursive;
}
.description p{
    font-size: 18px;
    font-family: 'Amiri', serif;
}
.carousel-boullt{
    position: absolute;
    bottom: -125px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel-boullt span{
    width: 45px;
    height: 12px;
    background-color: #283618;
    margin-left: 10px;
    border-radius: 10px;
    margin-top: 25px;
    cursor: pointer;
    box-shadow: 3px 2px 2px rgba(73, 72, 72, 0.338);
}
.carousel-boullt span:hover{
    background-color: #f8f0ba;
}
.ImageSlider{
    background-position: center ;
    background-repeat: no-repeat;
    height: 100%;
    
}
@media only screen and (max-width: 600px) {
    .Slider{
        height: 195%;
    }
}