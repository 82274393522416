.page_404{ 
    padding:40px 0; 
    background:#fff; 
    font-family: 'Prosto One', cursive;
}
.page_404  img{ 
    width:100%;
}
.four_zero_four_bg{
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}
.four_zero_four_bg h1{
 font-size:80px;
 color: #283618;
}
.four_zero_four_bg h3{
	font-size:80px;
    color: black;
}
			 
.link_404{			 
	color: #fff !important;
    padding: 10px 20px;
    background: linear-gradient(to right, #BC6C25, #663300);
    margin: 10px 0 20px 0;
    border-radius: 5px;
    display: inline-block;
}
.link_404:hover{	
    background: linear-gradient(to right, #c98951, #BC6C25);
    text-decoration: none;
}
.contant_box_404{ 
    margin-top:-50px;
    color: black;
}
.error-p{
    color: black;
    font-size: 17px;
}