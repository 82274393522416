* {
    box-sizing: border-box;
  }
.GalleryLogements {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    height: auto;
    border-radius: 25px;
    background-color: #FFFDF3;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 40px 25px 20px 0px;
    overflow-y: scroll;
}
.GalleryLogements::-webkit-scrollbar {
    display: none;
}
/* Tablet */

@media only screen and (max-width: 860px) {
    .GalleryLogements {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        height: auto;
        border-radius: 25px;
        background-color: #FFFDF3;
        padding-top: 140px;
        margin-top: 0;
    }
}